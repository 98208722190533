import React, { useEffect } from 'react';
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Link,
	useRouteMatch,
	useParams,
} from 'react-router-dom';

export default function App() {
	useEffect(() => {
		(function () {
			var deadline = '2021/10/30 21:00';

			function pad(num, size) {
				var s = '0' + num;
				return s.substr(s.length - size);
			}

			// fixes "Date.parse(date)" on safari
			function parseDate(date) {
				const parsed = Date.parse(date);
				if (!isNaN(parsed)) return parsed;
				return Date.parse(date.replace(/-/g, '/').replace(/[a-z]+/gi, ' '));
			}

			function getTimeRemaining(endtime) {
				let total = parseDate(endtime) - Date.parse(new Date());
				let seconds = Math.floor((total / 1000) % 60);
				let minutes = Math.floor((total / 1000 / 60) % 60);
				let hours = Math.floor((total / (1000 * 60 * 60)) % 24);
				let days = Math.floor(total / (1000 * 60 * 60 * 24));

				return { total, days, hours, minutes, seconds };
			}

			function clock(id, endtime) {
				let days = document.getElementById(id + '-days');
				let hours = document.getElementById(id + '-hours');
				let minutes = document.getElementById(id + '-minutes');
				let seconds = document.getElementById(id + '-seconds');

				var timeinterval = setInterval(function () {
					var time = getTimeRemaining(endtime);

					if (time.total <= 0) {
						clearInterval(timeinterval);
					} else {
						days.innerHTML = pad(time.days, 2);
						hours.innerHTML = pad(time.hours, 2);
						minutes.innerHTML = pad(time.minutes, 2);
						seconds.innerHTML = pad(time.seconds, 2);
					}
				}, 1000);
			}

			clock('js-clock', deadline);
		})();
	}, []);
	useEffect(() => {
		particlesJS.load('particles-js', '/particles.json', function () {
			console.log('callback - particles-js config loaded');
		});
	});
	return (
		<div>
			<meta charSet='utf-8' />
			<title>The Crypto Pot Club - 10 000 Minted NFTs</title>

			<meta
				content='CryptoPot consists of 10000 NFT, each having their own individual traits.'
				name='description'
			/>
			<meta content='width=device-width, initial-scale=1' name='viewport' />
			<link href='/dappStyle.css' rel='stylesheet' type='text/css' />
			<link href='/991.png' rel='shortcut icon' type='image/x-icon' />
			<link href='/991.png' rel='apple-touch-icon' />
			<style
				dangerouslySetInnerHTML={{
					__html: '\n .pre-loader {\n display: flex;\n}\n',
				}}
			/>
			<div className='page-wrapper'>
				<div className='navbar wf-section'>
					<div className='navbar-container'>
						<div className='nav-menu'>
							<a
								href='https://discord.gg/V9ZNBDAKPf'
								target='_blank'
								className='fixed-cta migration w-inline-block'
							>
								<div>Discord</div>
							</a>
							<a
								href='https://twitter.com/TheCryptoPotNFT'
								target='_blank'
								className='fixed-cta migration w-inline-block'
								style={{ marginRight: 130 }}
							>
								<div>twitter</div>
							</a>
							<a
								href='https://opensea.io/collection/the-crypto-pot'
								target='_blank'
								className='fixed-cta migration w-inline-block'
								style={{ marginRight: 260 }}
							>
								<div>Opensea</div>
							</a>
							<a
								href='#particles-js'
								data-w-id='b282fa53-c876-a363-d487-995c7a38630d'
								className='nav-link-dope-edition right-32 w-inline-block'
							>
								<div className='nav-text-wrap'>
									<div className='nav-text'>
										RoadMap
										<br />
										Roadmap
									</div>
									<div className='nav-text-hover'>how it works</div>
								</div>
							</a>
							<a
								href='#Team'
								data-w-id='b282fa53-c876-a363-d487-995c7a386319'
								className='nav-link-dope-edition right-32 w-inline-block'
							>
								<div className='nav-text-wrap'>
									<div className='nav-text'>
										Team
										<br />
										Team
										<br />‍
									</div>
									<div className='nav-text-hover'>pricing</div>
								</div>
							</a>
							<a
								href='#FAQ'
								data-w-id='b282fa53-c876-a363-d487-995c7a386325'
								className='nav-link-dope-edition right-32 w-inline-block'
							>
								<div className='nav-text-wrap'>
									<div className='nav-text'>
										Faq
										<br />
										Faq
									</div>
									<div className='nav-text-hover'>faq</div>
								</div>
							</a>
						</div>
						<div
							id='w-node-_03f60422-1655-11d8-4a86-7f6950630c73-1f2d1a68'
							className='text-block-3'
						>
							<strong>THE CRYPTO POT CLUB</strong>
						</div>
					</div>
				</div>
				<div
					data-w-id='b282fa53-c876-a363-d487-995c7a38632d'
					className='section grain top-140 wf-section'
				>
					<div className='container'>
						<div className='w-layout-grid hero-grid'>
							<div
								id='w-node-b282fa53-c876-a363-d487-995c7a386331-1f2d1a68'
								className='hero-content-wrap design-to-webflow'
							>
								<h1 className='hero-heading'>
									10 000 Unique Crypto Pot Ready to turn it ON
								</h1>
								<p className='paragraph-m max-560w margin-l margin-0-t-m'>
									Crypto Pot is a machine Robot the POT word Comes From jackpot
									machine , each CryptoPot having their own individual traits.
									<br />
									<h5>Reveal date - Oct 30th</h5>
								</p>

								<Link to='/mint' className='button hide-on-t-m w-button'>
									Mint HERE
								</Link>
							</div>
							<img
								src='/config/images/transparentGiff.gif'
								loading='lazy'
								alt=''
								className='image-10'
							/>
						</div>
					</div>
					<div className='circle-blur-top-left-2 safari' />
				</div>
				<div className='section grain padding-top-40 wf-section'>
					<div className='container'>
						<div className='w-layout-grid _4-col-grid'>
							<div className='benefits-wrapper-2'>
								<div className='icon-image-wrapper margin-s'>
									<img
										src='./config/images/checkMark.svg'
										loading='lazy'
										alt=''
										className='benefits-icon'
									/>
									<h4 className='margin-0'>Unique and Different</h4>
								</div>
								<p>
									Each Crypto Pot is unique and different from the next, meaning
									no two Crypto Pot's are alike!
								</p>
							</div>
							<div className='benefits-wrapper-2'>
								<div className='icon-image-wrapper margin-s'>
									<img
										src='./config/images/checkMark.svg'
										loading='lazy'
										alt=''
										className='benefits-icon'
									/>
									<h4 className='margin-0'>Safe and Secure</h4>
								</div>
								<p>
									Each NFT is minted onto the Ethereum block chain ensuring the
									rightful ownership of each Crypto Pot.
								</p>
							</div>
							<div className='benefits-wrapper-2'>
								<div className='icon-image-wrapper margin-s'>
									<img
										src='./config/images/checkMark.svg'
										loading='lazy'
										alt=''
										className='benefits-icon'
									/>
									<h4 className='margin-0'>Strong Community</h4>
								</div>
								<p>
									The Crypto Pot Club is filled with like minded NFT lovers who
									enjoy art as much as we do!
								</p>
							</div>
						</div>
					</div>
					<div className='circle-blur-bottom-right-2 safari' />
				</div>
				<div
					id='Mint'
					data-w-id='b282fa53-c876-a363-d487-995c7a386568'
					className='section wf-section'
				>
					<div className='div-block'>
						<h1 className='heading-3'>Mint</h1>
					</div>
					<div>
						<h4 className='heading-4'>0 /10 000</h4>
					</div>
					<main id='js-clock' className='js-clock'>
						<div className='box'>
							<div id='js-clock-days' className='clock-number'>
								00
							</div>
							<div className='clock-label'>Days</div>
						</div>
						<div className='box'>
							<div id='js-clock-hours' className='clock-number'>
								00
							</div>
							<div className='clock-label'>Hrs</div>
						</div>
						<div className='box'>
							<div id='js-clock-minutes' className='clock-number'>
								00
							</div>
							<div className='clock-label'>Min</div>
						</div>
						<div className='box'>
							<div id='js-clock-seconds' className='clock-number'>
								00
							</div>
							<div className='clock-label'>Sec</div>
						</div>
					</main>
					<div className='div-block-2'>
						<Link to='/mint' className='button-4 w-button'>
							Mint NOW
						</Link>
					</div>
					<div className='circle-blur-bottom-right-2 safari' />
				</div>
				<div
					id='particles-js'
					data-w-id='30c8d968-55fe-8251-987b-0e377becbfbb'
					className='section black wf-section'
				>
					<div className='container'>
						<div className='center-div max-720w margin-xl'>
							<h2 className='heading-2-small text-white'>Our Roadmap</h2>
						</div>
						<div className='w-layout-grid container small flex'>
							<div
								data-w-id='30c8d968-55fe-8251-987b-0e377becbfc3'
								className='timeline-wrapper'
							>
								<div className='grey-line'>
									<div className='white-line' />
								</div>
							</div>
							<div
								id='w-node-_30c8d968-55fe-8251-987b-0e377becbfc6-1f2d1a68'
								className='process-wrapper'
							>
								<div className='step-wrapper margin-l'>
									<div className='step'>
										<img
											src='/images/1.svg'
											loading='lazy'
											alt=''
											className='step-1-number'
										/>
										<div className='black-line' />
									</div>
									<div className='step-1-info-wrapper'>
										<div className='process-image'>
											<img src='/images/schema1.svg' loading='lazy' alt='' />
										</div>
										<div className='step-info'>
											<h4 className='step-heading margin-xs'>Phase 1 - 10%</h4>
											<p className='paragraph-m text-white margin-m'>
												Refuel The Saucer & sponsor!
											</p>
											<p className='text-light-grey'>
												-The team has been on the UFO for so long! In this
												phase, we get to repay the crew!
												<br />
												-do paid promotion on multi platforms to get more
												CryptoPoter's
											</p>
										</div>
									</div>
								</div>
								<div className='step-wrapper margin-l'>
									<div className='step'>
										<img
											src='/images/2.svg'
											loading='lazy'
											alt=''
											className='step-2-number'
										/>
										<div className='black-line' />
									</div>
									<div className='step-2-info-wrapper'>
										<div className='process-image'>
											<img src='/images/schema2.svg' loading='lazy' alt='' />
										</div>
										<div className='step-info'>
											<h4 className='step-heading margin-xs'>Phase 2 - 25%</h4>
											<p className='paragraph-m text-white margin-m'>
												Airdrop!
											</p>
											<p className='text-light-grey'>
												The community is 1/4th the way there! Let's run an
												airdrop to surprise some of you with some more
												CryptoPot!
											</p>
										</div>
									</div>
								</div>
								<div className='step-wrapper margin-l'>
									<div className='step'>
										<img
											src='/images/3.svg'
											loading='lazy'
											alt=''
											className='step-3-number'
										/>
										<div className='black-line' />
									</div>
									<div className='step-3-info-wrapper'>
										<div className='process-image'>
											<img src='/images/schema3.svg' loading='lazy' alt='' />
										</div>
										<div className='step-info'>
											<h4 className='step-heading margin-xs'>Phase 3 - 50%</h4>
											<p className='paragraph-m text-white margin-m'>
												Start Crypto Pot Porject
											</p>
											<p className='text-light-grey'>
												This project represents our wildest science ideas coming
												to fruition. The idea that we create a website where you
												can lunch you first 10k NFTs with easy steps and without
												coding on Ethereum blockchain <br />
												The NFT will be easy with Us
											</p>
										</div>
									</div>
								</div>
								<div className='step-wrapper margin-l'>
									<div className='step'>
										<img
											src='/images/4.svg'
											loading='lazy'
											alt=''
											className='step-4-number'
										/>
										<div className='black-line' />
									</div>
									<div className='step-4-info-wrapper'>
										<div className='process-image'>
											<img src='/images/schema4.svg' loading='lazy' alt='' />
										</div>
										<div className='step-info'>
											<h4 className='step-heading margin-xs'>Phase 4 - 75%</h4>
											<p className='paragraph-m text-white margin-m'>
												Wanna Try the Platform ?{''}
											</p>
											<p className='text-light-grey'>
												Launching the platform just for the People they bought
												the Crypto Pot NFTs
											</p>
										</div>
									</div>
								</div>
								<div className='step-wrapper'>
									<div className='step'>
										<img
											src='/images/5.svg'
											loading='lazy'
											alt='Step 5'
											className='step-5-number'
										/>
										<div className='black-line' />
									</div>
									<div className='step-5-info-wrapper'>
										<div className='process-image'>
											<img src='/images/schema5.svg' loading='lazy' alt='' />
										</div>
										<div className='step-info'>
											<h4 className='step-heading margin-xs'>
												Final Phase - 100%
											</h4>
											<p className='paragraph-m text-white margin-m'>
												Community Wallet and Upgrade the Crypto Pot Platform
											</p>
											<p className='text-light-grey'>
												- 5 ETH will be added to the community wallet !<br /> -
												we will add such new Futures to the Crypto Pot platform
												also we will add minting on polygon , Cardano , Solana ,
												avax
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div
					id='Team'
					data-w-id='b282fa53-c876-a363-d487-995c7a38648c'
					className='section grain wf-section'
				>
					<div className='container centred full-width'>
						<div className='center-div max-720w margin-xl'>
							<h2
								data-w-id='b282fa53-c876-a363-d487-995c7a38648f'
								style={{ opacity: 0 }}
								className='heading-2-small'
							>
								Meet the team{' '}
								<span className='with-underline long-underline'>of ALIENS</span>
							</h2>
							<p className='paragraph-m max-560w'>Meet our Project Creators!</p>
						</div>
						<div className='grid-2-col margin-l'>
							<div className='profile-wrapper'>
								<div className='profile-bg-colour'>
									<img
										src='./config/images/159.png'
										loading='eager'
										sizes='(max-width: 767px) 74vw, (max-width: 991px) 480px, (max-width: 1279px) 21vw, 24vw'
										srcSet='./config/images/159.png'
										alt='Portrait photo of co-founder Dan'
										className='profile-photo adam'
									/>
								</div>
								<div className='wrapper _16-margins'>
									<p className='paragraph-m text-centred'>
										<strong>OTHMAN KHO</strong> is incharge of all the
										development work in relation to Crypto Pot Club. Some would
										say his development skills are out of this world!
									</p>
								</div>
							</div>
							<div className='profile-wrapper'>
								<div className='profile-bg-colour'>
									<img
										src='./config/images/rare.png'
										loading='eager'
										sizes='(max-width: 767px) 74vw, (max-width: 991px) 480px, (max-width: 1279px) 21vw, 24vw'
										srcSet='./config/images/rare.png'
										alt='Portrait photo of co-founder Adam'
										className='profile-photo adam'
									/>
								</div>
								<div className='wrapper _16-margins'>
									<p className='paragraph-m text-centred'>
										{' '}
										the Cypto Pot Heart, <strong>OMAR KONBOR</strong> is
										incharge of ensuring all the aliens are doing amazing at
										there new homes. Without him, the CyptoPot might get broken!
									</p>
								</div>
							</div>
							<div className='profile-wrapper'>
								<div className='profile-bg-colour'>
									<img
										src='./config/images/9429.png'
										loading='eager'
										sizes='(max-width: 767px) 74vw, (max-width: 991px) 480px, (max-width: 1279px) 21vw, 24vw'
										srcSet='./config/images/9429.png'
										alt='Portrait photo of co-founder Adam'
										className='profile-photo adam'
									/>
								</div>
								<div className='wrapper _16-margins'>
									<p className='paragraph-m text-centred'>
										This project wouldn't be possible without our amazing
										designer <strong>SOHAILA KON</strong> Her art is super cool,
										just take a look at the CyptoPot!
									</p>
								</div>
							</div>
							<div className='profile-wrapper'>
								<div className='profile-bg-colour'>
									<img
										src='./config/images/9390.png'
										loading='eager'
										sizes='(max-width: 767px) 74vw, (max-width: 991px) 480px, (max-width: 1279px) 21vw, 24vw'
										srcSet='./config/images/9390.png'
										alt='Portrait photo of co-founder Adam'
										className='profile-photo adam'
									/>
								</div>
								<div className='wrapper _16-margins'>
									<p className='paragraph-m text-centred'>
										<strong>takasio</strong> is one of the main moderators for
										the CryptoPot Club! He ensures that all the CryptoPot owners
										are taken care of, keeping the community happy!
									</p>
								</div>
							</div>
							<div className='profile-wrapper'>
								<div className='profile-bg-colour'>
									<img
										src='./config/images/9408.png'
										loading='eager'
										sizes='(max-width: 767px) 74vw, (max-width: 991px) 480px, (max-width: 1279px) 21vw, 24vw'
										srcSet='./config/images/9408.png'
										alt='Portrait photo of co-founder Adam'
										className='profile-photo adam'
									/>
								</div>
								<div className='wrapper _16-margins'>
									<p className='paragraph-m text-centred'>
										<strong>SAMO.PILY</strong> is a crazy NFT guy. He eats,
										breathes, sleeps NFT's. If you have any NFT questions, NFT
										samo will most likely have an answer for you!
									</p>
								</div>
							</div>
							<div className='profile-wrapper'>
								<div className='profile-bg-colour'>
									<img
										src='./config/images/9374.png'
										loading='eager'
										sizes='(max-width: 767px) 74vw, (max-width: 991px) 480px, (max-width: 1279px) 21vw, 24vw'
										srcSet='./config/images/9374.png'
										alt='Portrait photo of co-founder Adam'
										className='profile-photo adam'
									/>
								</div>
								<div className='wrapper _16-margins'>
									<p className='paragraph-m text-centred'>
										Last but not least <strong>Ohmimid ! </strong>He mainly
										works inside the CyproPot engine so you won't seem him that
										much . But he keeps the CyptoPot turning!
									</p>
								</div>
							</div>
						</div>
					</div>
					<div className='circle-blur-bottom-left safari' />
				</div>
				<div id='FAQ' className='section grain testimonial wf-section'>
					<div className='container centred'>
						<div className='center-div margin-xxl'>
							<h2 className='heading-2-small'>Frequently asked questions</h2>
						</div>
						<div className='faq-wrapper margin-xl'>
							<div className='faq-block'>
								<div className='faq-top'>
									<h5 className='margin-0'>What’s an NFT?</h5>
									<img
										src='./config/images/clips.svg'
										loading='lazy'
										alt='Chevron'
										className='faq-arrow'
									/>
								</div>
								<div className='faq-bottom'>
									<p className='faq-answer'>
										An NFT stands for “Non-fungible token” and is a fancy way of
										saying it’s a unique, one of a kind digital item that users
										can buy, own, and trade. Some NFTs main function are to be
										digital art and look cool, some offer additional utility
										like exclusive access to websites or participation in an
										event, think of it like a rare piece of art that can also
										act as a “members” card.
										<br />
										<br />
										What is Metamask? Metamask is a crypto wallet that can store
										your Ethereum, and is needed to purchase and mint an Alien.
										Having a wallet gives you an Ethereum address (i.e.
										0xABCD….1234), this is where your NFT will be stored. Learn
										more about Metamask and how easy it is to use over here! (
										<a target='_blank' href='https://metamask.io/'>
											https://metamask.io/
										</a>
										)<br />
									</p>
								</div>
							</div>
							<div className='faq-block'>
								<div className='faq-top'>
									<h5 className='margin-0'>
										Where does my NFT go after I purchase an CyptoPot?
										<br />
									</h5>
									<img
										src='./config/images/clips.svg'
										loading='lazy'
										alt='Chevron'
										className='faq-arrow'
									/>
								</div>
								<div className='faq-bottom'>
									<p className='faq-answer'>
										Your CyptoPot NFT will appear in whatever address, or
										connected wallet you used to purchase the CyptoPot. You can
										also take a look at your CyptoPot on Opensea.
										<br />
									</p>
								</div>
							</div>
							<div className='faq-block'>
								<div className='faq-top'>
									<h5 className='margin-0'>
										This sounds awesome, how do I get involved?
										<br />
									</h5>
									<img
										src='./config/images/clips.svg'
										loading='lazy'
										alt='Chevron'
										className='faq-arrow'
									/>
								</div>
								<div className='faq-bottom'>
									<p className='faq-answer'>
										Head over to the Discord, jump in on the conversation and
										let us know your ideas!
										<br />
									</p>
								</div>
							</div>
							<div className='faq-block'>
								<div className='faq-top'>
									<h5 className='margin-0'>
										What can I do with my CyptoPot?
										<br />
									</h5>
									<img
										src='./config/images/clips.svg'
										loading='lazy'
										alt='Chevron'
										className='faq-arrow'
									/>
								</div>
								<div className='faq-bottom'>
									<p className='faq-answer'>
										You are free to do anything with them under a non-exclusive
										license.
										<br />
										<br />
									</p>
								</div>
							</div>
							<div className='faq-block'>
								<div className='faq-top'>
									<h5 className='margin-0'>
										Are these CyptoPot a good investment?
										<br />
									</h5>
									<img
										src='./config/images/clips.svg'
										loading='lazy'
										alt='Chevron'
										className='faq-arrow'
									/>
								</div>
								<div className='faq-bottom'>
									<p className='faq-answer'>
										That is ultimately a decision for you to make. We believe
										our little CyptoPot have a long life ahead of them, and will
										be an ever growing and evolving project. However the success
										of the CyptoPot Club relies on so many factors and
										variables, no one knows! Hopefully our CyptoPot can go to
										the moon, but like anything in life, don’t spend money you
										can’t afford to not have.
										<br />
									</p>
								</div>
							</div>
						</div>
						<h4>Have more questions?</h4>
						<p className='paragraph-m text-centred margin-l'>
							Just reach out, we’re always around to answer any questions.
						</p>
						<a
							href='https://discord.gg/V9ZNBDAKPf'
							className='button secondary small w-button'
						>
							contact us
						</a>
					</div>
					<a
						href='https://opensea.io/collection/the-crypto-pot'
						className='profile-photo adam'
					>
						<img
							src='./config/images/opensea.png'
							loading='lazy'
							alt='Chevron'
							className='profile-photo adam'
						/>
					</a>

					<div className='circle-blur-bottom-right safari' />
				</div>
				<div className='section footer wf-section'>
					<div className='container static'>
						<div className='footer-wrapper margin-xl'>
							<div
								id='w-node-b282fa53-c876-a363-d487-995c7a38659e-1f2d1a68'
								className='wrapper footer-logo'
							/>
						</div>
						<div className='footer-credits'>
							<p className='paragraph-s text-light-grey'>
								© 2021, KONBOR Solutions. All Rights Reserved.
							</p>
							<p className='text-link text-light-grey privacy-policy'>
								<a href='/privacy-policy' className='text-link text-light-grey'>
									Privacy Policy
								</a>
							</p>
						</div>
					</div>
				</div>
			</div>
			{/*[if lte IE 9]><![endif]*/}
			<style
				dangerouslySetInnerHTML={{
					__html:
						'\n.particles-js-canvas-el {\nposition: absolute;\nmax-width: 100%;\nmax-height:100%;\nleft: 0%;\ntop: 0%;\nright: 0%;\nbottom: 0%;\nz-index: 0;\n}',
				}}
			/>
		</div>
	);
}
